<template>
  <b-container fluid id="admin-savings-explorer">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <h1>Savings Explorer 🚀</h1>
      <b-form class="reducer-filter" @submit.prevent="fetchData" @reset.prevent="resetFilter">
        <!-- Date filters: client onboarded, report published -->
        <b-row>
          <!-- Client onboarded date filter -->
          <b-col cols="6" md="6" lg="2">
            <b-form-group label="CLIENT ONBOARDED FROM" label-for="clientOnboardedStart">
              <b-form-input
                v-model="filter.clientOnboardedStart"
                id="clientOnboardedStart"
                type="date"
                class="blue-border"
              />
              <div class="months-back-badges">
                <b-badge class="mt-2 querySelection" @click="setOnboardedDate(0)">
                  THIS MONTH
                </b-badge>
                <b-badge class="ml-2 querySelection" @click="setOnboardedDate(2)">
                  LAST 2 MONTHS
                </b-badge>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="6" md="6" lg="2">
            <b-form-group label="CLIENT ONBOARDED TO" label-for="clientOnboardedEnd">
              <b-form-input
                v-model="filter.clientOnboardedEnd"
                id="clientOnboardedEnd"
                type="date"
              />
            </b-form-group>
          </b-col>

          <!-- Report published date filter -->
          <b-col cols="6" md="6" lg="2">
            <b-form-group label="REPORT PUBLISHED FROM" label-for="reportPublishedStart">
              <b-form-input
                v-model="filter.reportPublishedStart"
                id="reportPublishedStart"
                type="date"
                class="blue-border"
              />
              <div class="months-back-badges">
                <b-badge class="mt-2 querySelection" @click="setPublishedDate(0)">
                  THIS MONTH
                </b-badge>
                <b-badge class="ml-2 querySelection" @click="setPublishedDate(2)">
                  LAST 2 MONTHS
                </b-badge>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="6" md="6" lg="2">
            <b-form-group label="REPORT PUBLISHED TO" label-for="reportPublishedEnd">
              <b-form-input
                v-model="filter.reportPublishedEnd"
                id="reportPublishedEnd"
                type="date"
              />
            </b-form-group>
          </b-col>

          <!-- Report shared date filter -->
          <b-col cols="6" md="6" lg="2">
            <b-form-group label="REPORT SHARED FROM" label-for="reportSharedStart">
              <b-form-input
                v-model="filter.reportSharedStart"
                id="reportSharedStart"
                type="date"
                class="blue-border"
                :disabled="filter.excludeShared"
              />
              <div class="months-back-badges d-flex align-items-end">
                <b-badge class="mt-2 querySelection" @click="setSharedDate(0)">
                  THIS MONTH
                </b-badge>
                <b-badge class="ml-2 querySelection" @click="setSharedDate(2)">
                  LAST 2 MONTHS
                </b-badge>
                <b-form-checkbox
                  class="d-inline ml-2"
                  v-model="filter.excludeShared"
                  @change="setExcludeShared($event)"
                >
                  NEVER SHARED
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="6" md="6" lg="2">
            <b-form-group label="REPORT SHARED TO" label-for="reportSharedEnd">
              <b-form-input
                v-model="filter.reportSharedEnd"
                id="reportSharedEnd"
                type="date"
                :disabled="filter.excludeShared"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Max/min saving and commission filters -->
        <b-row class="py-2">
          <b-col cols="6" md="6" lg="3">
            <b-form-group label="MINIMUM TOTAL SAVINGS" label-for="minSavings">
              <b-form-input
                v-model="filter.minSavings"
                id="minSavings"
                class="blue-border"
                type="number"
                min="0"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" md="6" lg="3">
            <b-form-group label="MAXIMUM TOTAL SAVINGS" label-for="maxSavings">
              <b-form-input v-model="filter.maxSavings" id="maxSavings" type="number" min="0" />
            </b-form-group>
          </b-col>
          <b-col cols="6" md="6" lg="3">
            <b-form-group label="MINIMUM TOTAL COMMISSION" label-for="minCommission">
              <b-form-input
                v-model="filter.minCommission"
                id="minCommission"
                class="green-border"
                type="number"
                min="0"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" md="6" lg="3">
            <b-form-group label="MAXIMUM TOTAL COMMISSION" label-for="maxCommission">
              <b-form-input
                v-model="filter.maxCommission"
                id="maxCommission"
                type="number"
                min="0"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- User, reprot states multiselects -->
        <b-row>
          <b-col cols="12" md="6">
            <label>SELECT ORGANISATION USERS</label>
            <multiselect
              v-model="filter.userIds"
              :options="usersOptions"
              track-by="userId"
              label="email"
              placeholder="Type to search"
              multiple
              taggable
            />
          </b-col>
          <b-col cols="12" md="6">
            <label>SELECT REPORT PUBLISHED BY USERS</label>
            <multiselect
              v-model="filter.publishedByUserEmails"
              :options="adminEmails"
              placeholder="Type to search"
              multiple
              taggable
            />
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col cols="12" md="4">
            <div>
              <label for="org-first-user">QUERY FIRST USER EMAIL</label>
            </div>
            <b-input v-model="filter.firstUserEmail" id="org-first-user" />
          </b-col>
          <b-col cols="10" md="6">
            <label>SHOW REPORT STATES</label>
            <multiselect
              v-model="selectedReportStates"
              :options="reportStateOptions"
              track-by="value"
              label="text"
              multiple
              taggable
              @remove="onStateRemove"
            />
          </b-col>
          <b-col cols="12" lg="2">
            <b-form-group label="EXCLUDE" label-for="excludeDummyData">
              <div id="excludeDummyData">
                <b-form-checkbox v-model="filter.excludeReducerEmails">
                  REDUCER EMAILS
                </b-form-checkbox>
                <b-form-checkbox v-model="filter.excludeDemoCompanies">
                  DEMO COMPANIES
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="filter-border mt-3"></div>

        <!-- Saving areas filter -->
        <b-row v-if="showSavingAreasFilter" class="mb-2">
          <b-col cols="12" md="6" class="mr-5">
            <b-checkbox v-model="showSavingAreasFilter" class="mb-2">
              ENABLE SAVING AREAS FILTER
            </b-checkbox>
            <multiselect
              v-model="filter.supplyTypeFilters"
              multiple
              :options="supplyTypeOptions"
              track-by="type"
              label="text"
              placeholder="Select areas to filter"
            />
          </b-col>
          <b-col cols="12" class="saving-areas-filter">
            <b-row>
              <b-col cols="6" v-for="area in filter.supplyTypeFilters" :key="area.type">
                <div class="area-filter">
                  <div class="filter-header d-flex align-items-center">
                    <label class="type-label mr-2">{{ area.text }}</label>
                    <label class="mr-1">Savings/Commission</label>
                    <b-checkbox
                      switch
                      v-model="area.filterNoQuotes"
                      @change="resetAreaFilter(area)"
                    />
                    <label>No Quotes</label>
                  </div>
                  <div v-if="!area.filterNoQuotes" class="mt-2">
                    <b-row>
                      <b-col cols="6">
                        <b-form-group
                          label="MINIMUM SAVINGS"
                          :label-for="`${area.type}-minSavings`"
                        >
                          <b-form-input
                            v-model="area.minSavings"
                            :id="`${area.type}-minSavings`"
                            class="blue-border"
                            type="number"
                            min="0"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group
                          label="MAXIMUM SAVINGS"
                          :label-for="`${area.type}-maxSavings`"
                        >
                          <b-form-input
                            v-model="area.maxSavings"
                            :id="`${area.type}-maxSavings`"
                            type="number"
                            min="0"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="MINIMUM COMMISSION"
                          :label-for="`${area.type}-minCommission`"
                        >
                          <b-form-input
                            v-model="area.minCommission"
                            :id="`${area.type}-minCommission`"
                            class="green-border"
                            type="number"
                            min="0"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="MAXIMUM COMMISSION"
                          :label-for="`${area.type}-maxCommission`"
                        >
                          <b-form-input
                            v-model="area.maxCommission"
                            :id="`${area.type}-maxCommission`"
                            type="number"
                            min="0"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                  <b-row v-else class="mt-2">
                    <b-col cols="6">
                      <b-form-group label="NO QUOTES REASON" label-for="areaNoQuotes">
                        <b-select
                          v-model="area.noQuotes"
                          :options="noQuotesValues"
                          id="areaNoQuotes"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- Form control buttons -->
        <b-row>
          <b-col class="d-flex justify-content-between">
            <b-checkbox v-if="!showSavingAreasFilter" v-model="showSavingAreasFilter">
              ENABLE SAVING AREAS FILTER
            </b-checkbox>
            <div class="d-flex ml-auto">
              <b-button class="reducer-btn" variant="primary" type="submit" :disabled="busy"
                >Search</b-button
              >
              <b-button class="ml-2" type="reset" :disabled="busy">Clear</b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-container>

    <b-container v-if="busy || total" fluid class="mt-5 min-height-se">
      <Busy v-if="busy" primary :size="2" class="pt-4" text="Generating summaries..." />

      <div v-else-if="!busy && total && total.clients" class="mt-5">
        <savings-explorer-overview admin :total="total" :summaries="summaries" />
        <savings-explorer-table admin :busy="busy" :summaries="summaries" :total="total" />
      </div>

      <p v-else-if="total" class="text-center pt-4">No results</p>
    </b-container>
  </b-container>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";
import Console from "@/console";
import SavingsExplorerTable from "@/components/SavingsExplorerTable";
import SavingsExplorerOverview from "@/components/SavingsExplorerOverview";
import SupplyTypes from "@/helper/supplytypehelper";
import moment from "moment";
import FormatHelper from "@/helper/formathelper";

export default {
  name: "SavingsExplorer",
  components: {
    Busy,
    SavingsExplorerTable,
    SavingsExplorerOverview
  },
  data() {
    return {
      title: "Savings Explorer",
      filter: {
        getAllSavings: true,
        organisationIds: [],
        userIds: [],
        publishedByUserEmails: [],
        excludeReportStates: [],
        clientOnboardedStart: null,
        clientOnboardedEnd: null,
        reportPublishedEnd: null,
        reportPublishedStart: null,
        reportSharedStart: null,
        reportSharedEnd: null,
        excludeReducerEmails: true,
        excludeDemoCompanies: true,
        supplyTypeFilters: [],
        firstUserEmail: null
      },
      busy: false,
      summaries: null,
      total: null,
      selectedReportStates: [
        { value: "complete", text: "Complete" },
        { value: "noneFound", text: "None found" },
        { value: "noneFoundNoMatches", text: "No matches" },
        { value: "noneFoundInsufficientData", text: "Insufficient data" }
      ],
      reportStateOptions: [
        { value: "complete", text: "Complete" },
        { value: "noneFound", text: "None found" },
        { value: "noneFoundNoMatches", text: "No matches" },
        { value: "noneFoundInsufficientData", text: "Insufficient data" }
      ],
      noQuotesValues: [
        { text: "Not applicable", value: null },
        { text: "On a good deal", value: "ON_GOOD_DEAL" },
        { text: "Not enough data", value: "NOT_ENOUGH_DATA" },
        { text: "In contract", value: "IN_CONTRACT" }
      ],
      supplyTypeOptions: [],
      showSavingAreasFilter: false,
      usersOptions: []
    };
  },
  computed: {
    adminEmails() {
      let emails = [];
      if (this.usersOptions) {
        emails = this.usersOptions.map(u => u.email).filter(e => e.includes("@reducer.co.uk"));
      }
      emails.push("robot@reducer.co.uk");
      return emails;
    }
  },
  created() {
    this.fetchUsers();
    this.supplyTypeOptions = SupplyTypes.supplyTypes().map(s => {
      return {
        type: s,
        text: SupplyTypes.toDisplay(s),
        filterNoQuotes: false,
        minSavings: null,
        maxSavings: null,
        minCommission: null,
        maxCommission: null,
        noQuotes: null
      };
    });
  },
  methods: {
    async fetchData() {
      this.busy = true;

      let client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}savings/get/summary`, this.getRequestBody())
        .then(response => {
          Console.log("Savings response: ", response);
          this.summaries = response.data.savingSummaries;
          this.total = response.data.total;
        })
        .catch(e => {
          this.showWarning("There was a problem fetching savings summaries.");
          console.error(e);
        })
        .finally(() => {
          this.busy = false;
        });
    },

    async fetchUsers() {
      let client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}users?fields=email&fields=id`)
        .then(response => {
          Console.log("Users response: ", response);
          this.usersOptions = response.data.users;
        })
        .catch(e => Console.error(e));
    },

    getRequestBody() {
      let reqBody = Object.assign({}, this.filter);

      if (reqBody.organisationIds.length > 0 || reqBody.userIds.length > 0) {
        reqBody.getAllSavings = false;
      }

      if (reqBody.userIds.length > 0) {
        reqBody.userIds = reqBody.userIds.map(u => u.userId);
      }

      if (this.reportStateOptions.length != this.selectedReportStates) {
        reqBody.excludeReportStates = this.reportStateOptions
          .filter(o => this.selectedReportStates.findIndex(s => s.value == o.value) == -1)
          .map(s => s.value);
      }

      reqBody.clientOnboardedStart = FormatHelper.formatDateToLocalDateTime(
        reqBody.clientOnboardedStart
      );
      reqBody.clientOnboardedEnd = FormatHelper.formatDateToLocalDateTime(
        reqBody.clientOnboardedEnd,
        false
      );
      reqBody.reportPublishedStart = FormatHelper.formatDateToLocalDateTime(
        reqBody.reportPublishedStart
      );
      reqBody.reportPublishedEnd = FormatHelper.formatDateToLocalDateTime(
        reqBody.reportPublishedEnd,
        false
      );
      reqBody.reportSharedStart = FormatHelper.formatDateToLocalDateTime(reqBody.reportSharedStart);
      reqBody.reportSharedEnd = FormatHelper.formatDateToLocalDateTime(
        reqBody.reportSharedEnd,
        false
      );

      if (!this.showSavingAreasFilter) {
        reqBody.supplyTypeFilters = null;
      }

      return reqBody;
    },

    resetFilter() {
      this.filter = {
        getAllSavings: true,
        organisationIds: [],
        userIds: [],
        excludeReportStates: [],
        clientOnboardedStart: null,
        clientOnboardedEnd: null,
        reportPublishedEnd: null,
        reportPublishedStart: null,
        excludeReducerEmails: true,
        excludeDemoCompanies: true,
        excludeShared: false,
        supplyTypeFilters: []
      };
      this.showSavingAreasFilter = false;
      (this.selectedReportStates = [
        { value: "complete", text: "Complete" },
        { value: "noneFound", text: "None found" },
        { value: "noneFoundNoMatches", text: "No matches" },
        { value: "noneFoundInsufficientData", text: "Insufficient data" }
      ]),
        this.$forceUpdate();
    },

    onStateRemove(reportState) {
      this.filter.excludeReportStates.push(reportState.value);
    },

    setOnboardedDate(monthsBack) {
      this.filter.clientOnboardedStart = moment()
        .subtract(monthsBack, "months")
        .format(monthsBack ? "YYYY-MM-DD" : "YYYY-MM-01");

      this.filter.clientOnboardedEnd = moment().format("YYYY-MM-DD");
    },

    setPublishedDate(monthsBack) {
      this.filter.reportPublishedStart = moment()
        .subtract(monthsBack, "months")
        .format(monthsBack ? "YYYY-MM-DD" : "YYYY-MM-01");

      this.filter.reportPublishedEnd = moment().format("YYYY-MM-DD");
    },

    setSharedDate(monthsBack) {
      this.filter.reportSharedStart = moment()
        .subtract(monthsBack, "months")
        .format(monthsBack ? "YYYY-MM-DD" : "YYYY-MM-01");

      this.filter.reportSharedEnd = moment().format("YYYY-MM-DD");
    },

    setExcludeShared(exclude) {
      if (exclude) {
        this.filter.reportSharedStart = null;
        this.filter.reportSharedEnd = null;
      }
    },

    resetAreaFilter(area) {
      area.minSavings = null;
      area.maxSavings = null;
      area.minCommission = null;
      area.maxCommission = null;
      area.noQuotes = null;
    },

    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    }
  }
};
</script>
<style lang="scss">
#admin-savings-explorer {
  @import "@/styles/common.scss";
  @include badge-styles;
  .container {
    max-width: 1450px;
  }
  .min-height-se {
    min-height: 100vh;
  }
  .blue-border {
    border-left: 4px solid $color-blue-lighter1;
  }

  .green-border {
    border-left: 4px solid $color-bright-green;
  }

  .querySelection {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .months-back-badges {
    white-space: nowrap;
    height: 2rem;
  }

  .saving-areas-filter {
    .area-filter {
      border: 1px solid $color-grey-lighter2;
      padding: 0.75rem;
      padding-bottom: 0;
      border-radius: 6px;
      margin-top: 0.5rem;
      .filter-header {
        label {
          font-size: 0.9rem !important;
          margin-bottom: 0;
        }
        .type-label {
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }
}
</style>
